import React from "react"
import "./styles.scss"
import Main from "../Main"

export default function StandardHeader({navLinks}) {
    return (
        <section className={"standard-site-header"}>
            <div className={"header-body"}>
                <Main/>
                <nav>
                    {navLinks}
                </nav>
            </div>
            <div className="gradient-strip"/>
        </section>
    )
}