import React from "react";
import "./styles.scss";
import { Link } from "gatsby";
import useLinks from "../../../../hooks/useLinks";
import logo from "../../../../images/imago-logo-full.svg"

export default function Main() {
    const links = useLinks();
    return <div className="header-main">
        <Link to={"/"}>
            <img className="logo" src={logo} alt={"Imago"}/>
        </Link>
    </div>
}