import React from "react"
import "./styles.scss"
import love from "../../images/transheart.svg";
import { IconEmail } from "../Icons";
import { Link } from "gatsby";

export default function Footer() {
    return (
        <footer className={"site-footer"}>
            <div className="gradient-strip"/>
            <div className="footer-contents">
                <p>© 2024 Imago All rights reserved | <Link to="/terms-of-use">Terms of Use</Link> | <Link to="/privacy-policy">Privacy Policy</Link></p>
                <a href="mailto:hello@imago.tg" className="email"><IconEmail/> hello@imago.tg</a>
                <p className={"credit"}>Made with <img src={love} alt={"love"}/> by cddlee</p>
            </div>
        </footer>
    )
}