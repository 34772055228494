import React from "react";
import "./styles.scss"

export default function CookieBanner({setAreCookiesAllowed}) {
    const allowCookies = allowed => {
        const getExpires = days => new Date(Date.now() + (days*24*60*60*1000)).toUTCString()
        if (allowed) {
            document.cookie = "allow_cookies=1;expires=" + getExpires(9999) + ";path=/";
            setAreCookiesAllowed(true)
        } else {
            document.cookie = "allow_cookies=0;expires=" + getExpires(1) + ";path=/";
            setAreCookiesAllowed(false)
        }
    }
    return <section className={"cookie-banner"}>
        <div>
            <p>We use cookies to personalize content and analyze access to our website. You can choose to accept only the cookies that are necessary for the website to function, or you can choose to allow tracking cookies. For more information, see our privacy policy.</p>
            <div>
                <button className={"button primary"} onClick={() => allowCookies(true)}>Accept all</button>
                <button className={"clear-button decline"} onClick={() => allowCookies(false)}>Accept only necessary</button>
            </div>
        </div>
    </section>
}