import React from "react"
import "./styles.scss"
import Main from "../Main";
import { IconMenu2 } from "../../../Icons";

export default function MobileHeader({expanded, setExpanded, titleBarRef}) {
    return (
        <section className={"mobile-site-header"}>
            <div className={"header-container fixed"}>
                <div className={"title-bar"} ref={titleBarRef}>
                    <Main/>
                    <button className={"nav-expand-button"} onClick={() => setExpanded(!expanded)} aria-label="Pokaż menu">
                        <IconMenu2/>
                    </button>
                </div>
            </div>
            <div className="gradient-strip"/>
        </section>
    )
}