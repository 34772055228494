import React, {useRef, useState} from "react";
import "./styles.scss"
import {Link} from "gatsby";
import StandardHeader from "./components/StandardHeader";
import MobileHeader from "./components/MobileHeader";
import useClickOutside from "../../hooks/useClickOutside";

export default function Header() {
    const [expanded, setExpanded] = useState(false)

    const navLinksDictionary = {
        "Home": "/",
        "Zofia's Story": "/zofia-s-story",
        "Principles & Values": "/principles-and-values",
        "Mission & Vision": "/mission-and-vision",
        "Services & Prices": "/services-and-prices",
        "FAQ": "/faq",
        "Blog": "/blog",
        "Support the Cause!": "/support-the-cause",
    }

    const navLinks = Object.entries(navLinksDictionary).map(([text, route], index) => route.startsWith("http") ?
    <a key={index} href={route} target="_blank" rel="nofollow noopener noreferrer" onClick={() => {setExpanded(false)}}>{text}</a> :
    <Link
        key={index} to={route} onClick={() => {setExpanded(false)}}>{text}
    </Link>)
    const refMobileHeader = useRef(null)
    const refMobileNav = useRef(null)
    useClickOutside([refMobileHeader, refMobileNav], () => setExpanded(false))
    return <div className="site-header-container">
    <nav className={`mobile-nav ${expanded ? "expanded" : ""}`} ref={refMobileNav}>
        {navLinks}
        <div className={"nav-end"}/>
    </nav>
    <header className={"site-header"}>  
        <StandardHeader navLinks={navLinks}/>
        <MobileHeader expanded={expanded} setExpanded={setExpanded} titleBarRef={refMobileHeader}/>
    </header>
    </div>
}